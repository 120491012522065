import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logoImage from "./images/logo.png";
import logoWhite from "./images/logo-white.png";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    services: false,
    moreLinks: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 50); // Adjust the scroll threshold as needed
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setDropdownOpen({ services: false, moreLinks: false });
  };

  const toggleDropdown = (dropdown) => {
    setDropdownOpen((prev) => ({ ...prev, [dropdown]: !prev[dropdown] }));
  };

  // Inline styles based on scrolling state
  const headerStyle = {
    color: scrolling ? "black" : "white",
    transition: "color 0.3s ease",
  };

  const liheader = {
    color: "black",
    transition: "color 0.3s ease",
  };

  return (
    <header
      className={`site-navbar light js-sticky-header site-navbar-target ${
        scrolling ? "scrolled" : ""
      }`}
      role="banner"
      style={headerStyle} // Apply inline style here
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-6 col-xl-2">
            <div className="mb-0 site-logo">
              <Link to="/" className="mb-0" style={headerStyle}>
                <img
                  src={scrolling ? logoImage : logoWhite}
                  alt="Logo"
                  width={"350vh"}
                />
              </Link>
            </div>
          </div>

          <div className="col-12 col-md-10 d-none d-xl-block">
            <nav
              className="site-navigation position-relative text-right my-2"
              role="navigation"
            >
              <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block lead">
                <li>
                  <Link to="/" className="active nav-link" style={headerStyle}>
                    Home
                  </Link>
                </li>
                <li className="has-children">
                  <Link to="#services" className="nav-link" style={headerStyle}>
                    Services
                  </Link>
                  <ul
                    className={`dropdown ${
                      dropdownOpen.services ? "open" : ""
                    }`}
                  >
                    <li>
                      <Link
                        to="/services"
                        className="nav-link"
                        onClick={closeMenu}
                        style={liheader}
                      >
                        All Services
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link
                    to="/testimonial"
                    className="nav-link"
                    onClick={closeMenu}
                    style={headerStyle}
                  >
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="nav-link" onClick={closeMenu} style={headerStyle}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" className="nav-link" onClick={closeMenu} style={headerStyle}>
                    Pricing
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/about"
                    className="nav-link"
                    onClick={closeMenu}
                    style={headerStyle}
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className="nav-link"
                    onClick={closeMenu}
                    style={headerStyle}
                  >
                    Contact
                  </Link>
                </li>
                <li className="social">
                  <Link
                    to="https://www.linkedin.com/company/the-emerald-care/"
                    className="nav-link"
                    onClick={closeMenu}
                    style={headerStyle}
                  >
                    <span className="fab fa-linkedin"></span>
                  </Link>
                </li>
                <li className="social">
                  <Link
                    to="https://www.instagram.com/the.emeraldcaregroup/?utm_source=qr&igsh=c3czM3J4dmpyN3ox"
                    className="nav-link"
                    onClick={closeMenu}
                    style={headerStyle}
                  >
                    <span className="fab fa-instagram"></span>
                  </Link>
                </li>
                <li className="social">
                  <Link
                    to="https://x.com/the_emeraldcare?t=yfkZxZerBeGvBlkhndiH_Q&s=08"
                    className="nav-link"
                    onClick={closeMenu}
                    style={headerStyle}
                  >
                    <span className="fab fa-x-twitter"></span>
                  </Link>
                </li>
                <li className="social">
                  <Link
                    to="https://www.tiktok.com/@the.emerald.caregroup?_t=8oZTUJ2a0E2&_r=1"
                    className="nav-link"
                    onClick={closeMenu}
                    style={headerStyle}
                  >
                    <span className="fab fa-tiktok"></span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-6 d-inline-block d-xl-none ml-md-0 py-3">
            <button
              onClick={toggleMenu}
              className={`site-menu-toggle js-menu-toggle float-right ${
                menuOpen ? "open" : ""
              }`}
            >
              <span className="icon-menu fas fa-bars"></span>
            </button>
          </div>
        </div>
      </div>

      {/* Responsive Menu */}
      <div className={`mobile-menu ${menuOpen ? "open text-black" : ""}`}>
        <ul className="mobile-menu-list">
          <li>
            <Link
              to="/"
              className="nav-link"
              onClick={closeMenu}
              style={liheader}
            >
              Home
            </Link>
          </li>
          <li className={`has-children ${dropdownOpen.services ? "open" : ""}`}>
            <Link
              className="nav-link"
              onClick={() => toggleDropdown("services")}
              style={headerStyle}
            >
              Services
            </Link>
            <ul className={`dropdown ${dropdownOpen.services ? "open" : ""}`}>
              <li>
                <Link
                  to="/services"
                  className="nav-link"
                  onClick={closeMenu}
                  style={liheader}
                >
                  All Services
                </Link>
              </li>
            </ul>
          </li>
          {/* <li>
            <Link to="/testimonial" className="nav-link" onClick={closeMenu} style={liheader}>
              Testimonials
            </Link>
          </li>
          <li>
            <Link to="/blog" className="nav-link" onClick={closeMenu} style={liheader}>
              Blog
            </Link>
          </li>
          <li>
            <Link to="/pricing" className="nav-link" onClick={closeMenu} style={liheader}>
              Pricing
            </Link>
          </li> */}
          <li>
            <Link
              to="/about"
              className="nav-link"
              onClick={closeMenu}
              style={liheader}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="nav-link"
              onClick={closeMenu}
              style={liheader}
            >
              Contact
            </Link>
          </li>
          <li className="social">
            <Link
              to="https://www.linkedin.com/company/the-emerald-care/"
              className="nav-link"
              onClick={closeMenu}
              style={liheader}
            >
              <span className="fab fa-linkedin"></span>
            </Link>
          </li>
          <li>
            <Link
              to="https://www.instagram.com/the.emeraldcaregroup/?utm_source=qr&igsh=c3czM3J4dmpyN3ox"
              className="nav-link"
              onClick={closeMenu}
              style={liheader}
            >
            <span className="fab fa-instagram"></span>
            </Link>
          </li>
          <li className="social">
            <Link
              to="https://x.com/the_emeraldcare?t=yfkZxZerBeGvBlkhndiH_Q&s=08"
              className="nav-link"
              onClick={closeMenu}
              style={liheader}
            >
              <span className="fab fa-x-twitter"></span>
            </Link>
          </li>
          <li className="social">
            <Link
              to="https://www.tiktok.com/@the.emerald.caregroup?_t=8oZTUJ2a0E2&_r=1"
              className="nav-link"
              onClick={closeMenu}
              style={liheader}
            >
              <span className="fab fa-tiktok"></span>
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
