import React from "react";
import Slider from "react-slick";
import "./stylesComponents.css";
import img from "../images/about.png";
import missionImg from "../images/mission.jpg"; // Example image for mission
import valuesImg from "../images/values.jpg";  // Example image for values
import visionImg from "../images/vision.jpg";  // Example image for vision

// Mission component with an image
const Mission = ({ iconClass, title, description, animationClass, imgSrc }) => (
  <div className="col-12">
    <div className="row" style={{ padding: "5px" }}>
      <div className="col-md-12">
        <div className={`card ${animationClass}`}>
          <span>
            <div className="card-body">
              {/* Image at the top of the card */}
              {imgSrc && (
                <div className="card-img-top">
                  <img
                    src={imgSrc}
                    alt={title}
                    style={{ width: "100%", height: "50vh" }}
                  />
                </div>
              )}
              <div className="row d-lg-flex flex-lg-row align-items-center mb-4">
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 d-flex justify-content-center">
                  <i
                    className={`fa ${iconClass}`}
                    style={{ fontSize: "40px", padding: "30px" }}
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="col-12 col-md-8 col-lg-9 col-xl-10" style={{ padding: "20px" }}>
                  <h6>{title}</h6>
                  {Array.isArray(description) ? (
                    <ul>
                      {description.map((item, index) => (
                        <li key={index} style={{color: "black"}}>{item}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>{description}</p>
                  )}
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
);

const MissionsCard = () => {
  // Slick slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <div className="container" style={{ marginTop: "10vh", marginBottom: "10vh" }}>
      <div className="row">
        {/* <div className="col-md-3 d-flex flex-row align-items-center">
          <img
            src={img}
            style={{ width: "90%", margin: "3vh" }}
            alt="mission vision values"
          />
        </div> */}
        <div className="col-md-12">
          {/* Slider for the Mission Cards */}
          <Slider {...settings}>
            <div>
              <Mission
                iconClass="fas fa-bullseye"
                title="Our Mission"
                description="To provide compassionate, comprehensive care to individuals with Traumatic Brain Injuries, helping them achieve their potential and reintegrate into society with dignity."
                animationClass="cssanimation fadeInBottom"
                imgSrc={missionImg} // Pass mission image here
              />
            </div>
            <div>
              <Mission
                iconClass="fa fa-handshake"
                title="Our Values"
                description={[
                  "Compassion: We treat all clients with empathy and kindness.",
                  "Respect: We honor the dignity of each individual.",
                  "Excellence: We strive for the highest standards in care and services.",
                  "Integrity: We act with honesty and transparency in all our dealings.",
                  "Innovation: We embrace new methods and technologies to improve client outcomes.",
                  "Family Inclusion: We believe in the importance of family involvement in the recovery process."
                ]}
                animationClass="cssanimation2 fadeInBottom2"
                imgSrc={valuesImg} // Pass values image here
              />
            </div>
            <div>
              <Mission
                iconClass="fa fa-eye"
                title="Our Vision"
                description="To be the leading provider of innovative and compassionate care for TBI clients, recognized for our dedication to client success and family inclusion."
                animationClass="cssanimation3 fadeInBottom3"
                imgSrc={visionImg} // Pass vision image here
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MissionsCard;
