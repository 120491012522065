import React from "react";
import PropTypes from "prop-types";
import image from "../images/pricing-caregiver-meal.jpg";
import { Link } from "react-router-dom";

const SlideItem = ({
  title = "Care and Well-Being of individuals across the country.",
  subtitle = "Welcome to Emerald Care Group, an inpatient facility dedicated to the comprehensive care of Traumatic Brain Injury (TBI) clients across the country. Our family-operated business is committed to the success and well-being of our clients and their families. With years of experience in the TBI field, we offer personalized, high-quality programs designed to meet the unique needs of each individual.",
  buttonText = "Contact Us",
  buttonLink = "/contact",
}) => (
  <div
    className="slide-item overlay"
    style={{ backgroundImage: `url(${image})` }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-9 mx-auto mt-4 pt-4 d-flex flex-column justify-content-center align-items-center text-center">
          <h3 className="text-white ">{title}</h3>
          <p className="text-white">{subtitle}</p>
          <p>
            <Link to="/services" className="btn btn-primary">
              Services
            </Link>
            &nbsp;
            <Link to={buttonLink} className="btn btn-primary">
              {buttonText}
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
);

SlideItem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default SlideItem;
