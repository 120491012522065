import React, { useState } from "react";
import image from "./images/slider-2.jpg";
import emailjs from "emailjs-com"; // Import EmailJS

const ContactUs = () => {
  const currentDate = new Date();

  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options) + ' EAT';
  };

  const formattedDate = formatDate(currentDate);

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: "",
    user_subject: "",
    contact_number: "697483",
    current_year: currentDate.getFullYear(),
    current_date: formattedDate,
  });
  

  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loader state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage("");

    try {
      // First, save the contact data using PHP
      const response = await fetch('http://localhost/php_backend/save_contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData),
      });

      const result = await response.json();

      if (result.status === "success") {
        setResponseMessage(result.message);

        // Now send an email using EmailJS
        await emailjs.send("service_fhokp27", "template_cs2267s", formData, "SKptiKk56LtKRz2EW");

        // Set a professional response message
        setResponseMessage("Thank you for reaching out, " + formData.user_name + "! Your message has been sent successfully. We appreciate your feedback and will get back to you shortly. If you have any urgent inquiries, please feel free to contact us directly at admin@theemeraldcaregroup.com.");

        // Clear the form data after successful submission.
        setFormData({
          user_name: "",
          user_email: "",
          message: "",
          user_subject: "",
          contact_number: "697483",
        });
      } else {
        setResponseMessage(result.message);
      }

    } catch (error) {
      console.error("Error:", error);
      setResponseMessage("An error occurred while saving your contact.");
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div>
      <div className="slide-item overlay" style={{ backgroundImage: `url(${image})` }}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 align-self-center">
              <h3 className="text-white mb-3">Contact Us</h3>
              <p className="text-white">
                We'd love to hear from you! Reach out to us with any questions or concerns.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-5">
              {loading ? (
                <div className="text-center mb-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p>Sending your message...</p>
                </div>
              ) : (
                <form id="contact-form" onSubmit={handleSubmit}>
                  {responseMessage && (
                    <div className="alert alert-success">{responseMessage}</div>
                  )}

                  <input type="hidden" name="contact_number" value={formData.contact_number} />

                  <div className="form-group">
                    <label htmlFor="user_name">Name</label>
                    <input
                      type="text"
                      name="user_name"
                      className="form-control"
                      value={formData.user_name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="user_subject">Subject</label>
                    <input
                      type="text"
                      name="user_subject"
                      className="form-control"
                      value={formData.user_subject}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="user_email">Email</label>
                    <input
                      type="email"
                      name="user_email"
                      className="form-control"
                      value={formData.user_email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      className="form-control"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder="Write your message here..."
                    ></textarea>
                  </div>

                  <input
                    type="submit"
                    value="Send"
                    className="btn btn-primary text-white"
                    disabled={loading} // Disable button while loading
                  />
                </form>
              )}
            </div>

            {/* Contact Details Section */}
            <div className="col-lg-4 ml-lg-auto">
              <ul className="list-unstyled contact-details">
                <li className="mb-4 p-3 border rounded">
                  <p className="mb-1 font-weight-bold text-dark">Address</p>
                  <p>
                    <a href="tel:+18132192212" className="text-muted contact-link">
                      Tampa, Florida
                    </a>
                  </p>
                </li>
                <li className="mb-4 p-3 border rounded">
                  <p className="mb-1 font-weight-bold text-dark">Phone</p>
                  <p>
                    <a href="tel:+18132192212" className="text-muted contact-link">
                      +1 (813) 219-2212
                    </a>
                  </p>
                </li>
                <li className="mb-4 p-3 border rounded">
                  <p className="mb-1 font-weight-bold text-dark">Email</p>
                  <p>
                    <a href="mailto:info@example.com" className="text-muted contact-link">
                      admin@theemeraldcaregroup.com
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
